export default [
  {
    path: 'home',
    label: 'Start',
  },
  {
    path: 'feature',
    label: 'Funkcje',
  },
  {
    path: 'testimonial',
    label: 'Konfiguracja',
  },
  {
    path: 'pricing',
    label: 'Premium',
  },
];
